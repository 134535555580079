import React from "react";

export default function PageNotFound() {
  return (
    <div className="section">
      <div className="container">
        <p>Page not found</p>
      </div>
    </div>
  );
}
